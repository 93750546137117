import React, { ReactNode } from "react"
import loadable from "@loadable/component"
import { HeroText } from "./HeroText"
import { HorizAlignType, HeroItemProps } from "typings/modules"
import { useCommonMediaQuery } from "hooks/useMediaQuery"
import {
  BgImage,
  NoBgContainer,
  Container,
  PlayerContainer,
  StyledImg,
  ContentContainer,
} from "./HeroFixedLayout.styles"
import { GridContainer } from "./common.styles"
import { getImage } from "utils/imageUtils"
import {
  SanityVideoAsset,
  SanityImageAsset,
  SanityHotspotAssetOrImageAssetOrVideoAsset,
} from "typings/graphql"
import { generateAltContentIndex } from "utils/altTextUtils"
const Video = loadable(() => import("components/UI/Video/Video"))

export const HeroFixedLayout = ({
  item,
  $isSlideshow,
  index,
  $mode,
  $mobileMode,
}: HeroItemProps) => {
  const { isMobile, isTablet, isDesktop } = useCommonMediaQuery()
  const mobileAsset = item?.asset?.mobileAsset[0]
  const desktopAsset = item?.asset?.desktopAsset[0]
  const fallbackAlt = generateAltContentIndex(
    item?._rawContent,
    index,
    item?.internalName
  )
  const asset: SanityHotspotAssetOrImageAssetOrVideoAsset = isMobile
    ? mobileAsset
    : desktopAsset
  const vidAsset = asset as SanityVideoAsset
  const imageAsset = asset as SanityImageAsset
  const autoplay = vidAsset?.autoplay
  const altText = imageAsset?.altText ?? fallbackAlt
  const videoId = vidAsset?.video?.asset?.playbackId
  const mobileImage = getImage(imageAsset?.image)
  const desktopImage = getImage(imageAsset?.image)
  const bgColor = item?.bgColor?.opacityHex
  const desktopAlign = (item?.desktopAlign ?? "center") as HorizAlignType
  const firstItem = index === 0
  const HeroBg = ({ children }: { children: ReactNode }) =>
    desktopImage && (isTablet || isDesktop) ? (
      <>
        <BgImage
          image={desktopImage.gatsbyImage}
          loading={firstItem ? "eager" : "lazy"}
          $bgColor={bgColor}
          alt={altText}
        />
        {children}
      </>
    ) : (
      <NoBgContainer $bgColor={bgColor}>{children}</NoBgContainer>
    )
  return (
    <Container>
      <HeroBg>
        <PlayerContainer>
          {isMobile && mobileImage && (
            <StyledImg
              loading={firstItem ? "eager" : "lazy"}
              image={mobileImage.gatsbyImage}
              alt={altText}
            />
          )}
          {videoId && <Video playbackId={videoId} autoplay={autoplay} />}
        </PlayerContainer>
        <ContentContainer $isSlideshow={$isSlideshow}>
          <GridContainer $horizAlign={desktopAlign}>
            <HeroText item={item} $mode={$mode} $mobileMode={$mobileMode} />
          </GridContainer>
        </ContentContainer>
      </HeroBg>
    </Container>
  )
}

export default HeroFixedLayout
