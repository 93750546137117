import styled from "styled-components"
import { mq } from "styles"
import { $DesktopTextAlign } from "typings/modules"

export const Backdrop = styled.div<{ textBgColor: string }>`
  ${mq.minWidth("lg")} {
    ${({ textBgColor }) =>
      textBgColor &&
      `
      background-color: ${textBgColor};
      padding: 32px;
      border-radius: var(--ui-rounding);
    `}
  }
`

export const AlignWrapper = styled.div<$DesktopTextAlign>`
  text-align: center;
  ${mq.minWidth("lg")} {
    text-align: ${({ $desktopTextAlign }) => $desktopTextAlign};
  }
`

export const CtaContainer = styled(AlignWrapper)`
  margin-top: 24px;

  ${mq.minWidth("lg")} {
    margin-top: 32px;
  }
`
