import { mq } from "styles"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { $BgColor, $IsSlideshow } from "typings/modules"

export const Container = styled.div`
  position: relative;
  display: grid;
  background: ${({ theme }) => theme.localTheme?.canvas?.primary?.opacityHex};
  width: 100%;
`

const bgStyles = css<$BgColor>`
  display: block;
  height: 100%;
  padding: 0;
  background: ${({ $bgColor }) => $bgColor};
  ${mq.minWidth("md")} {
    display: grid;
    height: 80vh;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
`

export const NoBgContainer = styled.section<$BgColor>`
  ${bgStyles};
`

export const BgImage = styled(GatsbyImage)<$BgColor>`
  ${bgStyles};
`

export const PlayerContainer = styled.div`
  height: 50vh;
  position: relative;
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  ${mq.minWidth("md")} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 0;
    height: 100%;
  }
`

export const StyledImg = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const contentFixedStyles = ({ $isSlideshow }: $IsSlideshow) => css`
  padding: ${$isSlideshow ? "32px 0 64px" : "32px 0"};
`

export const ContentContainer = styled.div<$IsSlideshow>`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  ${contentFixedStyles};
  ${mq.minWidth("md")} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: 0;
    align-content: center;
    height: 100%;
  }
  ${mq.minWidth("lg")} {
    grid-template-columns: repeat(24, 1fr);
  }
`
