import styled from "styled-components"
import { mq } from "styles"

export const EyebrowIcon = styled.img`
  display: inline-block;
  max-width: 100%;
  height: auto;
  margin-bottom: 24px;

  ${mq.minWidth("md")} {
    margin-bottom: 32px;
  }
`
