import React from "react"
import { HorizAlignType, HeroItemProps, $Mode, ModeType } from "typings/modules"
import { tracking } from "utils/tracking"
import { Backdrop, AlignWrapper, CtaContainer } from "./HeroText.styles"
import { Button, ButtonVariant } from "components/UI/Button/Button"
import {
  LargeAndUp,
  MediumAndBelow,
} from "components/UI/MediaQueryContainers/MediaQueryContainers"
import { PortableText } from "components/UI/PortableText/PortableText"
import { EyebrowIcon } from "components/UI/EyebrowIcon"

type IsText = {
  isText?: boolean
}

type HeroTextProps = IsText & Omit<HeroItemProps, "$isSlideshow">

type HeroButtonProps = {
  ctaLink: string
  internalName: string
} & $Mode

type HeroTextContentProps = {
  $ctaMode: ModeType
} & $Mode

const HeroButton: React.FC<HeroButtonProps> = ({
  $mode,
  ctaLink,
  internalName,
  children,
}) => {
  return (
    <Button
      variant={`primary-${$mode}` as ButtonVariant}
      to={ctaLink}
      onClick={() => {
        tracking.elementClicked(internalName, "Hero")
      }}
    >
      {children}
    </Button>
  )
}
export const HeroText = ({
  item,
  isText,
  $mode,
  $mobileMode,
}: HeroTextProps) => {
  const eyebrowIcon = item?.eyebrowIcon?.asset?.url
  const titleContent = item?._rawTitleContent
  const internalName = item?.internalName || ""
  const textBgColor = item?.textBgColor?.opacityHex
  const textColor = item?.textColor?.opacityHex
  const content = item?._rawContent
  const ctaText = item?.ctaText
  const ctaLink = item?.ctaLink
  const ctaTheme = item?.ctaTheme as ModeType
  const mobileCtaTheme = item?.mobileCtaTheme as ModeType
  const desktopAlign = (item?.desktopAlign ?? "center") as HorizAlignType
  const isAlignLeft = desktopAlign !== "center"
  const desktopTextAlign = isAlignLeft ? (isText ? "center" : "left") : "center"
  const hasContent = titleContent || content || ctaLink

  const HeroTextContent = ({ $mode, $ctaMode }: HeroTextContentProps) => {
    return (
      <Backdrop textBgColor={textBgColor}>
        {eyebrowIcon && (
          <AlignWrapper $desktopTextAlign={desktopTextAlign}>
            <EyebrowIcon src={eyebrowIcon} alt="" />
          </AlignWrapper>
        )}
        {titleContent && (
          <AlignWrapper $desktopTextAlign={desktopTextAlign}>
            <PortableText
              blocks={titleContent}
              $mode={$mode}
              $textColor={textColor}
              keepLastElementBottomPadding
            />
          </AlignWrapper>
        )}
        {content && (
          <AlignWrapper $desktopTextAlign={desktopTextAlign}>
            <PortableText
              blocks={content}
              $mode={$mode}
              $textColor={textColor}
              keepLastElementBottomPadding
            />
          </AlignWrapper>
        )}
        {ctaLink && (
          <CtaContainer $desktopTextAlign={desktopTextAlign}>
            <HeroButton
              $mode={$ctaMode}
              ctaLink={ctaLink}
              internalName={internalName}
            >
              {ctaText}
            </HeroButton>
          </CtaContainer>
        )}
      </Backdrop>
    )
  }

  return hasContent ? (
    <div>
      <LargeAndUp>
        <HeroTextContent $mode={$mode} $ctaMode={ctaTheme || $mode} />
      </LargeAndUp>
      <MediumAndBelow>
        <HeroTextContent
          $mode={$mobileMode}
          $ctaMode={mobileCtaTheme || $mobileMode}
        />
      </MediumAndBelow>
    </div>
  ) : null
}
