import { mq } from "styles"
import styled, { css } from "styled-components"
import { HorizAlign } from "typings/modules"

const gridAlignCalc = ({ $horizAlign }: HorizAlign) => {
  if ($horizAlign === "left") return "3 / span 7"
  if ($horizAlign === "right") return "15 / span 7"
  return "8 / span 10"
}

const gridLargeStyles = ({ $horizAlign }: HorizAlign) => css`
  grid-column: ${gridAlignCalc({ $horizAlign })};
`

export const GridContainer = styled.div<HorizAlign>`
  display: grid;
  grid-column: 2 / span 10;
  position: relative;
  ${mq.minWidth("lg")} {
    ${gridLargeStyles};
  }
`
